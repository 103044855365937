<template>
    <div>
      <div class="wrap">
        <div class="top-area">
          <div class="title">
            Create
          </div>
          <div class="step">
            <div class="txt" :class="{active:step === 1}">NFT 정보</div><div class="right-arrow"></div>
            <div class="txt" :class="{active:step === 2}">와인 정보</div><div class="right-arrow"></div>
            <div class="txt" :class="{active:step === 3}">와인 설명</div>
          </div>
        </div>

        <!-- Step1 -->
        <div class="flex-row" v-if="step === 1">
          <div class="form">
            <div class="image-area" @click="openFile" :style="previewImage">
              <div class="field-image-file" v-if="!setPreviewImage">
                <div class="ic-plus"></div>
                <div class="title">
                  Please select a wine NFT image.
                </div>
                <div class="subject">
                  Only jpg and png files can be uploaded.<br/>
                  Recommended size : 600*600
                </div>
              </div>
            </div>
            <div class="info-area">
              <!--<dl>
                <dt>Brand Name</dt>
                <dd>
                  <input type="text" placeholder="Please enter your brand name" v-model="wineInfo.brand"/>
                </dd>
              </dl>-->
              <dl>
                <dt>Minter</dt>
                <dd>
                  {{wineInfo.appendInfo.minter}}
                </dd>
              </dl>
              <dl>
                <dt>Wine Name</dt>
                <dd>
                  <input type="text" placeholder="Please enter your wine name" v-model="wineInfo.name"/>
                </dd>
              </dl>
              <dl>
                <dt>NFT Generated quantity</dt>
                <dd>
                  <input type="number" placeholder="Please enter the quantity to be issued" v-model="wineInfo.count" />
                </dd>
              </dl>
              <dl>
                <dt>
                  Sell Price
                  <span class="unit-translate">({{$numberFormat((priceWon)).split('.')[0]}}원)</span>
                </dt>
                <dd>
                  <select v-model="wineInfo.coinName">
                    <option value="USDC" selected>USDC</option>
                  </select>
                  <input type="number" placeholder="Please enter a price(USDC)" v-model="wineInfo.price">
                </dd>
              </dl>
              <!--<div class="fee-guide">
                <div class="guide-tooltip">
                  <div class="fee-title">
                    Fee
                    <span>4%</span>
                  </div>
                  <div class="fee-explain">
                    This value includes service fee.
                  </div>
                </div>
              </div>-->
              <div class="next-step-btn" @click="moveStep(2)">Next</div>
            </div>
          </div>
        </div>

        <!-- Step2 -->
        <div class="flex-row" v-if="step === 2">
          <div class="form flex-align-start">
            <div class="image-area-t2" :style="previewImage">

            </div>
            <div class="info-area">
              <!--<div class="brand-name">
                {{wineInfo.brand}}
              </div>-->
              <div class="nft-name">
                {{wineInfo.name}}
              </div>
              <div class="stock-tooltip">
                <div class="line">
                  <div class="title">생성 수량</div>
                  <div class="content">{{wineInfo.count}}</div>
                </div>
                <div class="line">
                  <div class="title">판매 가격</div>
                  <div class="content">
                    {{wineInfo.price}} {{wineInfo.coinName}}
                    <span>({{$numberFormat(priceWon).split('.')[0]}}원)</span>
                  </div>
                </div>
              </div>
              <dl>
                <dt>
                  Type/Grape
                </dt>
                <dd style="gap: 5px;">
                  <DefaultDropDown :list-item="wineTypeList" :selectedData.sync="wineInfo.appendInfo.type"></DefaultDropDown>
                  <input type="text" placeholder="Please enter grapes." v-model="wineInfo.appendInfo.grape">
                </dd>
              </dl>
              <dl>
                <dt>Winery</dt>
                <dd>
                  <input type="text" placeholder="Please enter winery." v-model="wineInfo.appendInfo.winery" />
                </dd>
              </dl>
              <dl>
                <dt>Format</dt>
                <dd>
                  <input type="text" placeholder="Please enter Format(ex. 750ml)" v-model="wineInfo.appendInfo.format"/>
                </dd>
              </dl>
              <dl>
                <dt>Case * Bottle</dt>
                <dd>
                  <input type="text" placeholder="1 * 1" v-model="wineInfo.appendInfo['case * bottle']">
                  <!--<label class="winex-radio"><input type="radio" name="radio-test" value="bottle" v-model="wineInfo.appendInfo.case" checked><span>bottle</span></label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label class="winex-radio"><input type="radio" name="radio-test" value="case" v-model="wineInfo.appendInfo.case"><span>case</span></label>-->
                </dd>
              </dl>
              <dl>
                <dt>Country</dt>
                <dd>
                  <input type="text" placeholder="Please enter country." v-model="wineInfo.appendInfo.country"/>
                </dd>
              </dl>
              <dl>
                <dt>Region</dt>
                <dd>
                  <input type="text" placeholder="Please enter region." v-model="wineInfo.appendInfo.region" />
                </dd>
              </dl>
              <dl>
                <dt>Subregion 1</dt>
                <dd>
                  <input type="text" placeholder="Please enter first subregion." v-model="wineInfo.appendInfo.subregion_1" />
                </dd>
              </dl>
              <dl>
                <dt>Subregion 2</dt>
                <dd>
                  <input type="text" placeholder="Please enter second subregion." v-model="wineInfo.appendInfo.subregion_2" />
                </dd>
              </dl>
              <dl>
                <dt>Subregion 3</dt>
                <dd>
                  <input type="text" placeholder="Please enter third subregion." v-model="wineInfo.appendInfo.subregion_3" />
                </dd>
              </dl>
              <dl>
                <dt>Grade</dt>
                <dd>
                  <input type="text" placeholder="Please enter grade." v-model="wineInfo.appendInfo.grade" />
                </dd>
              </dl>
              <dl>
                <dt>Vineyard</dt>
                <dd>
                  <input type="text" placeholder="Please enter vineyard." v-model="wineInfo.appendInfo.vineyard" />
                </dd>
              </dl>
              <dl>
                <dt>Vintage</dt>
                <dd>
                  <input type="text" placeholder="Please enter vintage." v-model="wineInfo.appendInfo.vintage" />
                </dd>
              </dl>
              <dl>
                <dt>Winex Minting Vintage(WMV)</dt>
                <dd>
                  <input type="number" placeholder="Please enter Winex Minting Vintage (ex. 2018)" v-model="wineInfo.appendInfo.wmv" />
                </dd>
              </dl>
<!--              <dl>-->
<!--                <dt>Serial Number</dt>-->
<!--                <dd>-->
<!--                  <input type="text" placeholder="Winex Minting Serial Number" v-model="wineInfo.appendInfo.serial_number" />-->
<!--                </dd>-->
<!--              </dl>-->


              <div class="btn-row">
                <div class="prev-step-btn" @click="moveStep(1)">
                  Previous
                </div>
                <div class="next-step-btn-2" @click="moveStep(3)">
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Step3 -->
        <div class="flex-row" v-if="step === 3">
          <div class="form flex-align-start">
            <div class="image-area-t2" :style="previewImage">
            </div>
            <div class="info-area">
              <!--<div class="brand-name">
                {{wineInfo.brand}}
              </div>-->
              <div class="nft-name">
                {{wineInfo.name}}
              </div>

              <!-- 와인 특성 정보 -->
              <div class="detail-wine-info-wrap">
                <ul class="detail-wine-info-list">

                  <!--<li class="detail-wine-info-item" v-for="(property, key) in wineInfo.appendInfo" v-bind:key="key">
                    <span class="detail-wine-info-title">{{changePropertyFirstUpper(key)}}</span>
                    <span class="detail-wine-info-content">{{property?property:"-"}}</span>
                  </li>-->

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title" style="font-weight: bold;">Type</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.type?wineInfo.appendInfo.type:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title" style="font-weight: bold;">Grape</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.grape?wineInfo.appendInfo.grape:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Winery</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.winery?wineInfo.appendInfo.winery:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Format</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.format?wineInfo.appendInfo.format:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Case * Bottle</span>
                    <span class="detail-wine-info-content">{{wineInfo["appendInfo"]["case * bottle"]?wineInfo["appendInfo"]["case * bottle"]:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Country</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.country?wineInfo.appendInfo.country:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Region</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.region?wineInfo.appendInfo.region:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">SubRegion1</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.subregion_1?wineInfo.appendInfo.subregion_1:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">SubRegion2</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.subregion_2?wineInfo.appendInfo.subregion_2:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">SubRegion3</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.subregion_3?wineInfo.appendInfo.subregion_3:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Grade</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.grade?wineInfo.appendInfo.grade:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Vineyard</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.vineyard?wineInfo.appendInfo.vineyard:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Vintage</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.vintage?wineInfo.appendInfo.vintage:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">Minter</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.minter?wineInfo.appendInfo.minter:"-"}}</span>
                  </li>

                  <li class="detail-wine-info-item">
                    <span class="detail-wine-info-title">WMV</span>
                    <span class="detail-wine-info-content">{{wineInfo.appendInfo.wmv?wineInfo.appendInfo.wmv:"-"}}</span>
                  </li>

                </ul>
              </div>

              <div class="stock-tooltip">
                <div class="line">
                  <div class="title">NFT Generated quantity</div>
                  <div class="content">{{wineInfo.count}}</div>
                </div>
                <div class="line">
                  <div class="title">Sell Price</div>
                  <div class="content">
                    {{wineInfo.price}} {{wineInfo.coinName}}
                    <span>({{$numberFormat(priceWon).split('.')[0]}}원)</span>
                  </div>
                </div>
              </div>
              <dl>
                <dt>Description</dt>
                <dd>
                  <textarea placeholder="Description" v-model="wineInfo.description"></textarea>
                </dd>
              </dl>

              <div class="btn-row">
                <div class="prev-step-btn" @click="moveStep(2)">
                  Previous
                </div>
                <div class="next-step-btn-2" @click="submitNftMinting">
                  Complete
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertModal
              :title="modal.title"
              :content="modal.content"
              :showWait="modal.showWait"
              :pcWidth="500"
              :button-content="'확인'"
              :button-class="'primary-btn'"
              :is-open="flagModalOnOff"
              :confirm-click-btn="closeModal">
      </AlertModal>
    </div>
</template>
<script>
    import getContractApi, { WineMintingStep } from "../../../api/contract";
    import AlertModal from "../../../components/modals/AlertModal";
    import DefaultDropDown from "../../../components/dropdowns/DefaultDropDown";

    export default {
      name: 'CreateNft',
      components: {
        DefaultDropDown,
        AlertModal

      },
        created() {
          let self = this
          this.contract = getContractApi();
          this.market = this.contract.getWineMarket();

          // console.dir('ddd')
          this.contract.getUserAddress().then(account => {
            // 내 지갑 주소
            self.account = account
            self.$store.dispatch('creator/getCreator', { wallet_address: account }).then((data) => {
              // self.wineInfo.appendInfo.minterName = data.nickname
              self.wineInfo.appendInfo.minter = data.nickname
            }).catch(() => {
              alert("권한이 없습니다.")
              self.$router.push('/')
            })
          });
        },
        data () {
            return  {
              contract: null,
              market: null,
              inputImage:null,
              previewImage:{},
              setPreviewImage:false,
              flagModalOnOff:false,
              step:1,
              wineTypeList:[
                {
                  value:"Red",
                  text:"Red",
                },
                {
                  value:"White",
                  text:"White"
                },
                {
                  value:"Sparkling",
                  text:"Sparkling"
                }
              ],
              modal : {
                title: "NFT 생성 완료",
                content: "와인 NFT가 민팅 완료 되었습니다."
              },
              wineInfo: {
                name:"",
                count:1,
                price:0,
                coinName:"USDC",
                description:"",
                deep:true,
                appendInfo: {
                  type:"Red",
                  grape:"",
                  winery:"",
                  format:"750ml",
                  "case * bottle":"1 * 1",
                  country:"France",
                  region:"Bourgogne",
                  subregion_1:"",
                  subregion_2:"",
                  subregion_3:"",
                  grade:"",
                  vineyard:"",
                  vintage:"",
                  minter:"",
                  wmv:"2023"
                }
              },
              createdNFT: "",
              account:""
            }
        },
        methods: {
          openFile() {
            let input = document.createElement('input');
            let self = this
            input.type = 'file';
            input.accept='image/*'
            input.addEventListener('change', function () {
              self.inputImage = input.files[0]
              self.setPreviewImage = true
              let previewImage = URL.createObjectURL(self.inputImage)
              self.previewImage = {backgroundImage:`url(${previewImage})`}
            })
            input.click()
          },
          submitNftMinting(){
            const titleMsg = "NFT 생성";
            let self = this

            this.$store.commit('loading/init',{title:titleMsg, content:'NFT 생성 중)'})
            this.contract.getUserAddress().then(address => {
              if (!address)
                throw Error("지갑을 연결해주세요.");
              return this.market.mint(this.wineInfo, this.inputImage, (statusMsg,stVal) => {
                switch (statusMsg) {
                  case WineMintingStep.Start:
                    this.$store.commit('loading/init',{title:titleMsg,content:'NFT 생성을 시작합니다!'})
                    break;
                  case WineMintingStep.ImageUpload:
                    if(stVal > 0) {
                      let msg = `이미지를 업로드 중입니다! ${stVal}%`;
                      this.$store.commit('loading/init',{title:titleMsg,content:msg})
                    } else {
                      this.$store.commit('loading/init',{title:titleMsg,content:'이미지를 업로드 중입니다!'})
                    }
                    break;
                  case WineMintingStep.CreateMetaData:
                    this.$store.commit('loading/init',{title:titleMsg,content:'메타 데이터 생성 중입니다!'})
                    break;
                  case WineMintingStep.Minting:
                    this.$store.commit('loading/init',{title:titleMsg,content:'NFT 민팅 중입니다!'})
                    break;
                  case WineMintingStep.CheckOwner:
                    this.$store.commit('loading/init',{title:titleMsg,content:'소유자 확인 중입니다!'})
                    break;
                }
              });
            }).then(key => {
              // 키값 찍어 보기
              // console.dir("key List ==>")
              // console.dir(key)

              let appendWineList = [];
              for(let i = 0; i < key.length; i++) {
                this.createdNFT = key;
                let splitCreatedNFT = key[i].split('/')
                let formData = new FormData();
                formData.append('image', self.inputImage)
                formData.append('wineInfo', JSON.stringify(self.wineInfo))
                formData.append('minter_key', self.account)
                formData.append('nft_key', splitCreatedNFT[1]);
                formData.append('market_key', splitCreatedNFT[0])
                appendWineList.push(self.$store.dispatch('phpNFT/appendWineNFT', formData))
              }

              Promise.all(appendWineList).then(() => {
                self.flagModalOnOff = true;
                self.setModal(titleMsg, "와인 NFT가 민팅 완료 되었습니다.");
                self.$store.commit('loading/done')
              }).catch(() => {
                alert('와인 등록중 오류가 발생하였습니다.')
              })



            }).catch(e => {
              console.log(e);
              this.$store.commit('loading/done')
              this.flagModalOnOff = true;
              if (e.name === "UserFriendlyError" && e.message) {
                this.setModal("오류", e.message);
              } else {
                this.setModal("오류", "NFT 생성을 실패하였습니다.");
              }
            })
          },
          setModal(title,content) {
            this.modal.title = title
            this.modal.content = content
          },
          setModalWait(showWait) {
              this.modal.showWait = showWait
          },
          changePropertyFirstUpper(text) {
            return text.replaceAll('_', ' ').split(' ').map(v => {
              return v.charAt(0).toUpperCase() + v.slice(1);
            }).join(' ');
          },
          moveStep(step) {
            switch (step) {
              case 1:
                this.step = 1
                break;
              case 2:
                    if(this.wineInfo.name === "") {
                      this.setModal('알림','와인명을 입력해 주세요.')
                      this.flagModalOnOff = true;
                    } else if(this.wineInfo.count <= 0) {
                      this.setModal('알림','생성 수량을 1이상으로 입력해 주세요.')
                      this.flagModalOnOff = true;
                    } else if(this.wineInfo.price <= 0) {
                      this.setModal('알림','판매 가격을 1이상으로 입력해 주세요.')
                      this.flagModalOnOff = true;
                    } else if(this.inputImage === null) {
                      this.setModal('알림','이미지를 선택해 주세요.')
                      this.flagModalOnOff = true;
                    } else {
                      this.step = 2
                    }
                break;
              case 3:
                this.step = 3
                break;
            }

          },
          closeModal() {
            // 예외 분기 처리 필요 : ex. 민팅 실패 시, TIME OUT 시
            // ---
            // 모달 닫기
            this.flagModalOnOff = false;
            // 민팅 정상 완료 시 이동
            if (this.createdNFT) {
              this.$router.push('/market/list');
            }
          },
        },
        computed: {
          priceWon() {
            return this.contract.toWon(this.wineInfo.price);
          }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/create-nft.scss";
</style>
