<template>
    <div class="default-dropdown-wrap" :class="{on:optionListOpen}">
        <div class="dropdown-item-selected" @click="toggleList" :style="setHeight">
            <span class="placeholder" :style="{color:selectColor}">{{dataPlaceHolder}}</span>
            <span class="dropdown-icon"><span></span> </span>
        </div>
        <VueSlideToggle :open="optionListOpen" :duration="200" class="dropdown-item-list">
            <ul>
                <li v-for="item in listItem" v-bind:key="item.value" @click="clickItem(item)" :style="setHeight">{{item.text}}</li>
            </ul>
        </VueSlideToggle>
    </div>
</template>

<script>
    import {VueSlideToggle} from 'vue-slide-toggle'
    export default {
        name: "DefaultDropDown",
        props: {
            placeHolder: {
                default: "선택",
                type:String
            },
            listItem: {
                default:[
                    {
                        value:1,
                        text:"유형1"
                    },
                    {
                        value:2,
                        text:"유형2"
                    },
                    {
                        value:3,
                        text:"유형3"
                    },
                    {
                        value:4,
                        text:"유형4"
                    },
                    {
                        value:5,
                        text:"유형5"
                    },
                    {
                        value:6,
                        text:"유형6"
                    },
                    {
                        value:7,
                        text:"유형7"
                    },
                    {
                        value:8,
                        text:"유형8"
                    },
                ],
                required:true
            },
            listHeight: {
                default:52
            }
        },
        components: {
            VueSlideToggle
        },
        data() {
            return {
                optionListOpen:false,
                selectedData:null,
                selectColor:"#CBCBCB",
                selectedColor: "#303030",
                dataPlaceHolder: this.placeHolder,
                setHeight:{}
            }
        },
        mounted() {
            if(this.listHeight) {
                this.setHeight={lineHeight:this.listHeight+"px", height:this.listHeight+"px"}
            }
        },
        methods: {
            toggleList() {
                this.optionListOpen = !this.optionListOpen
            },
            clickItem(item) {
                this.dataPlaceHolder = item.text
                this.optionListOpen = false
                this.selectColor = this.selectedColor
                this.$emit('update:selectedData',item.value)
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
